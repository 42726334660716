import { Table, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DeviceWidth from "context/MobileResizeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./builders.module.css";
import TopTab from "components/TopTab/TopTab";
import classNames from "classnames";
import axios from "config/axios";
import { navigate } from "@reach/router";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";

export function AllHomeowners(props: ReachPathProps) {
  const isMobile = useContext(DeviceWidth);
  const [homeowners, setHomeowners] = useState<HomeownerObject[]>([]);

  const [loading, setLoading] = useState<boolean>();

  const { handleSearch, isSearching, searchQuery } = useSearch();

  const getAllBuilders = useCallback((page: number = 0, size: number = 10) => {
    setLoading(true);
    axios
      .get(`homeowner/home-owners`)
      .then(({ data }) => {
        setHomeowners(data.HomeOwners);
        setLoading(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    getAllBuilders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let result = homeowners.filter((homeowner) => {
    return (
      homeowner.firstName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      homeowner.lastName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      homeowner.email.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      homeowner.accountType.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1
    );
  });

  const columns = [
    {
      title: "Full NAME",
      dataIndex: "firstName",
      key: "firstName",
      ellipsis: true,
      render: (_: any, record: HomeownerObject) => (
        <Tooltip title={record.firstName + " " + record.lastName}>
          {record.firstName + " " + record.lastName}
        </Tooltip>
      ),
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text}>
          <span style={{ textTransform: "lowercase" }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
      key: "accountType",
      ellipsis: true,
      render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    },
  ];

  return (
    <div className={styles.container}>
      <TopTab links={[{ to: "", name: "All Home Owners" }]} />
      <div className={classNames([styles.topbar, "c-end"])}>
        <Input
          prefix={<SearchOutlined />}
          size="middle"
          onChange={(e) => handleSearch(e)}
          allowClear
          style={{ width: isMobile ? "100%" : "250px", paddingRight: "1rem" }}
        />
      </div>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table
          rowClassName={styles["table-row"]}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`homeowners/details/${record.id}`);
              },
            };
          }}
          columns={columns}
          dataSource={isSearching ? result : homeowners}
          className={styles.table}
          loading={loading}
          size="middle"
          style={{
            textTransform: "capitalize",
            fontSize: "0.2rem",
            minWidth: "600px",
          }}
        />
      </div>
    </div>
  );
}
