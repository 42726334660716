import TopTab from "components/TopTab/TopTab";
import styles from "./transactions.module.css";
import axios from "config/axios";
import dayjs from "dayjs";
import DeviceWidth from "context/MobileResizeContext";
import { useContext, useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { Table, Typography, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useSearch from "hooks/useSearch";
const { Text } = Typography;

const History = (props: ReachPathProps) => {
  const isMobile = useContext(DeviceWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get("transaction/getallcompletedtransaction");

        setTransactions(res.data.transactions);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  // const paidReder = (text: string | boolean) => {
  //   return (
  //     <>{text ? <Text style={{ color: "#00962C" }}>Paid</Text> : <Text style={{ color: "#FF612F" }}>Pending</Text>}</>
  //   );
  // };
  let searchResult = transactions.filter((transaction) => {
    return (
      transaction.frontendId.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      transaction.builderName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      transaction.supplier.businessName
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.logisticsPartner.businessName
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.trackingStatus
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(transaction.createdAt)
        .format("MMM DD, YYYY")
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (text: string, record: any) => (
        <Text>{text?.substring(0, 8) ?? record?.order?.invoiceNumber}</Text>
      ),
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) =>
        dayjs(text).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "NAME",
      dataIndex: "builderName",
      key: "builderName",
      ellipsis: true,
      render: (text: string, record: any) => (
        <Tooltip
          title={
            text
              ? text
              : record.homeOwner?.firstName + " " + record.homeOwner?.lastName
          }
        >
          {text
            ? text
            : record.homeOwner?.firstName + " " + record.homeOwner?.lastName}
        </Tooltip>
      ),
    },
    {
      title: "SUPPLIER",
      dataIndex: "supplier",
      key: "supplier",
      ellipsis: true,
      render: (text: BuilderObject) => (
        <Tooltip title={text.businessName}>{text.businessName}</Tooltip>
      ),
    },
    {
      title: "TRACKING STATUS",
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (text: string) => <Text>{text}</Text>,
    },
    // {
    //   title: "TOTAL",
    //   dataIndex: "totalPrice",
    //   key: "totalPrice",
    //   ellipsis: true,
    //   render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    // },
  ];

  const webTableData = [];
  for (let i = 0; i < 100; i++) {
    webTableData.push({
      key: "id",
      "order id": "#34543",
      date: "11 Oct 2019",
      builder: "Global Real Estate LTD",
      supplier: "Chisco Building LTD",
      "payment status": "PAID",
      "delivery status": "PENDING",
      total: "₦90,000,000.00",
    });
  }
  const mobileTableData: any = [];
  transactions.map((t: any) => {
    return mobileTableData.push({
      key: "1",
      "column-one": [t.id, "order date", t.createdAt],
      "column-two": [t.builderName, "payment ststua", t.paymentStatus],
      "column-three": [t.totalPrice, "delivery status", t.deliveryStatus],
    });
  });
  return (
    <>
      <TopTab
        links={[
          { to: "../", name: "Unpaid" },
          { to: "../pending", name: "Pending" },
          { to: "", name: "History" },
        ]}
      />
      <div className={styles.container}>
        <div className={styles.topbar}>
          <Input
            className={styles.search}
            prefix={<SearchOutlined />}
            placeholder="search"
            onChange={(e) => handleSearch(e)}
            allowClear
            size="middle"
            style={{ width: isMobile ? "100%" : "auto", marginRight: "1rem" }}
          />
        </div>
        <div className={styles.table} style={{ overflowX: "auto" }}>
          {isSearching ? (
            <Table
              loading={isLoading}
              dataSource={searchResult}
              columns={webTableColumns}
              rowClassName={styles["table-row"]}
              onRow={(row) => {
                return {
                  onClick: (event) => {
                    navigate(`history/${row.order.id}`);
                  },
                };
              }}
              style={{
                minWidth: "600px",
              }}
              size="middle"
            />
          ) : (
            <Table
              loading={isLoading}
              dataSource={transactions}
              columns={webTableColumns}
              rowClassName={styles["table-row"]}
              onRow={(row) => {
                return {
                  onClick: (event) => {
                    navigate(`history/${row.order.id}`);
                  },
                };
              }}
              style={{
                minWidth: "600px",
              }}
              size="middle"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default History;
