import {
  Button,
  Divider,
  message,
  Skeleton,
  Space,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import Title from "antd/lib/typography/Title";
import styles from "./builders.module.css";
import { useContext, useEffect, useState } from "react";
import axios from "config/axios";
import { navigate, useMatch } from "@reach/router";
import Spinner from "assets/icons/Spinner";
import DeviceWidth from "context/MobileResizeContext";
import requestErrorHandler from "util/requestErrorHandler";
import useModules from "hooks/useModules";

const HomeOwnerInfo = ({
  homeowner,
  isLoading,
}: {
  homeowner?: HomeownerObject;
  isLoading: Boolean;
}) => {
  return (
    <div>
      <>
        <Space className={styles.cta}>
          {isLoading && (
            <>
              <Skeleton.Button />
              <Skeleton.Button />
            </>
          )}
        </Space>
        <Divider />

        {isLoading && (
          <>
            <Skeleton.Input size="large" style={{ width: 1000, height: 350 }} />
          </>
        )}
        {!isLoading && (
          <>
            <div className={styles["details-card"]}>
              <span>
                Full Name
                <b>{homeowner?.firstName + " " + homeowner?.lastName}</b>
              </span>
              <span>
                Account Type
                <b>{homeowner?.accountType}</b>
              </span>
              <span>
                Zip/postal code
                <b>{homeowner?.firstName}</b>
              </span>
              <span>
                State / Country
                <b>{homeowner?.firstName}, Nigeria</b>
              </span>
              <span>
                Email address
                <b style={{ textTransform: "lowercase" }}>
                  {homeowner?.email}{" "}
                </b>
              </span>
            </div>
          </>
        )}
      </>
    </div>
  );
};

const Projects = ({
  homeowner,
  isLoading,
}: {
  homeowner?: HomeownerObject;
  isLoading: Boolean;
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (_: any, record: any) => (
        <Tooltip title={record.name}>{record.name}</Tooltip>
      ),
    },
    {
      title: "Location",
      dataIndex: "locationName",
      key: "locationName",
      ellipsis: true,
      render: (_: any, record: any) => (
        <Tooltip title={record.locationName}>{record.locationName}</Tooltip>
      ),
    },
    {
      title: "Contractor Name",
      dataIndex: "contractorName",
      key: "contractorName",
      ellipsis: true,
      render: (_: any, record: any) => (
        <Tooltip title={record.contractorName}>{record.contractorName}</Tooltip>
      ),
    },
    {
      title: "Contractor Phone",
      dataIndex: "contractorPhone",
      key: "contractorPhone",
      ellipsis: true,
      render: (_: any, record: any) => (
        <Tooltip title={record.contractorPhone}>
          {record.contractorPhone}
        </Tooltip>
      ),
    },
    {
      title: "Contractor Email",
      dataIndex: "contractorEmail",
      key: "contractorEmail",
      ellipsis: true,
      render: (_: any, record: any) => (
        <Tooltip title={record.contractorEmail}>
          {record.contractorEmail}
        </Tooltip>
      ),
    },
  ];
  return (
    <div>
      <>
        <Space className={styles.cta}>
          {isLoading && (
            <>
              <Skeleton.Button />
              <Skeleton.Button />
            </>
          )}
        </Space>
        <Divider />

        {isLoading && (
          <>
            <Skeleton.Input size="large" style={{ width: 1000, height: 350 }} />
          </>
        )}
        {!isLoading && (
          <>
            <div style={{ width: "100%", overflowX: "auto" }}>
              <Table
                rowClassName={styles["table-row"]}
                columns={columns}
                dataSource={homeowner?.homeOwnerProject}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      navigate(`project-details/${record.id}`);
                    },
                  };
                }}
                className={styles.table}
                loading={isLoading}
                size="middle"
                style={{
                  textTransform: "capitalize",
                  fontSize: "0.2rem",
                  minWidth: "600px",
                }}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};
const HomeownerDetails = (props: ReachPathProps) => {
  const { TabPane } = Tabs;
  const isMobile = useContext(DeviceWidth);
  const [homeowner, setHomeowner] = useState<HomeownerObject>();
  const [isLoading, setIsLoading] = useState(false);

  const match = useMatch("/admin/homeowners/details/:id") as {
    uri: string;
    path: string;
    id: string;
  };

  useEffect(() => {
    setIsLoading(true);
    getBuilder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.id]);
  const getBuilder = () => {
    axios
      .get(`homeowner/one-home-owner/?homeOwnerId=${match.id}`)
      .then(({ data }) => {
        setHomeowner(data.homeOwner);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }
  // const { builder } = useContext(BuilderContext);
  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <Title level={5}>
          <Button
            style={{ padding: 0 }}
            type="text"
            onClick={() => navigate(-1)}
          >
            Home Owner
          </Button>
        </Title>
        <span></span>
      </div>

      <Tabs defaultActiveKey="1" type="card" size="large" centered={isMobile}>
        <TabPane tab="Home Owner Info" key="1">
          <HomeOwnerInfo homeowner={homeowner} isLoading={isLoading} />
        </TabPane>
        <TabPane tab="Projects" key="2">
          <Projects homeowner={homeowner} isLoading={isLoading} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default HomeownerDetails;
