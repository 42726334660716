import { Router } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import { AllHomeowners } from "./AllHomeowners";
import HomeownerDetails from "./HomeownerDetails";
import ProjectDetails from "./ProjectDetails";
import Timeline from "./Timeline";

export default function Homeowners(props: ReachPathProps) {
  const path = window.location.pathname.includes("details")
    ? "Home Owner Detail"
    : "Home Owners";
  return (
    <DashboardShared title={path}>
      <Router>
        <AllHomeowners path="/" />
        <HomeownerDetails path="details/:id" />
        <ProjectDetails path="details/project-details/:id" />
        <Timeline path="details/project-details/timeline/:id" />
      </Router>
    </DashboardShared>
  );
}
