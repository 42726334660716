import { navigate, Router } from "@reach/router";
import { Table, Typography, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DashboardShared from "components/DashboardShared/DashboardShared";
import TopTab from "components/TopTab/TopTab";
import DeviceWidth from "context/MobileResizeContext";
import { useContext, useEffect, useState } from "react";
import History from "./history";
import styles from "./transactions.module.css";
import axios from "config/axios";
import dayjs from "dayjs";
import useSearch from "hooks/useSearch";
import Unpaid from "./Unpaid";
import UnpaidDetails from "./unpaid-details";
import OrderDetails from "./OrderDetails";
import TransactionDetail from "./transaction-details";

const { Text } = Typography;
const Pending = () => {
  const isMobile = useContext(DeviceWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { handleSearch, isSearching, searchQuery } = useSearch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get("transaction/getallpendingtransaction");
        setTransactions(res.data.transactions);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  // const paidReder = (text: string | boolean) => {
  //   return (
  //     <>{text ? <Text style={{ color: "#00962C" }}>Paid</Text> : <Text style={{ color: "#FF612F" }}>Pending</Text>}</>
  //   );
  // };

  let searchResult = transactions.filter((transaction) => {
    return (
      transaction.frontendId.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      transaction.builderName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      transaction.supplier.businessName
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.logisticsPartner.businessName
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction.trackingStatus
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(transaction.createdAt)
        .format("MMM DD, YYYY")
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "order",
      key: "order",
      render: (text: any) => <Text>{text?.invoiceNumber}</Text>,
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) =>
        dayjs(text).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "TYPE",
      dataIndex: "builderId",
      key: "builderId",
      ellipsis: true,
      render: (text: string) => (
        <Tooltip title={text ? "BUILDER" : "HOMEOWNER"}>
          {text ? "BUILDER" : "HOMEOWNER"}
        </Tooltip>
      ),
    },
    {
      title: "NAME",
      dataIndex: "builderName",
      key: "builderName",
      ellipsis: true,
      render: (text: string, record: any) => (
        <Tooltip
          title={
            text
              ? text
              : record.homeOwner?.firstName + " " + record.homeOwner?.lastName
          }
        >
          {text
            ? text
            : record.homeOwner?.firstName + " " + record.homeOwner?.lastName}
        </Tooltip>
      ),
    },
    {
      title: "SUPPLIER",
      dataIndex: "supplier",
      ellipsis: true,
      render: (text: BuilderObject) => (
        <>
          <Tooltip title={text.businessName}>
            {text?.businessName ?? "--"}
          </Tooltip>
        </>
      ),
    },
    // {
    //   title: "DSP",
    //   dataIndex: "logisticsPartner",
    //   ellipsis: true,
    //   render: (text: BuilderObject) => (
    //     <>{/* <Tooltip title={text}>{text.businessName ?? "--"}</Tooltip> */}</>
    //   ),
    // },
    {
      title: "TRACKING STATUS",
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (text: string) => <Text>{text.replace(/_/g, " ")}</Text>,
    },
    // {
    //   title: "TOTAL",
    //   dataIndex: "totalPrice",
    //   key: "totalPrice",
    //   ellipsis: true,
    //   render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    // },
  ];

  const mobileTableData: any = [];
  transactions.map((t: any, i) => {
    return mobileTableData.push({
      id: t.id,
      key: i,
      "column-one": [t.id, "order date", t.createdAt],
      "column-two": [t.builderName, "payment ststua", t.paymentStatus],
      "column-three": [t.totalPrice, "delivery status", t.deliveryStatus],
    });
  });

  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <Input
          className={styles.search}
          prefix={<SearchOutlined />}
          placeholder="search"
          onChange={(e) => handleSearch(e)}
          allowClear
          size="middle"
          style={{ width: isMobile ? "100%" : "auto", marginRight: "1rem" }}
        />
      </div>
      <div className={styles.table} style={{ overflowX: "auto" }}>
        {isSearching ? (
          <Table
            loading={isLoading}
            dataSource={searchResult}
            columns={webTableColumns}
            rowClassName={styles["table-row"]}
            onRow={(row) => {
              return {
                onClick: (event) => {
                  navigate(`${row.id}`);
                },
              };
            }}
            style={{
              minWidth: "600px",
            }}
            size="middle"
          />
        ) : (
          <Table
            loading={isLoading}
            dataSource={transactions}
            columns={webTableColumns}
            rowClassName={styles["table-row"]}
            onRow={(row) => {
              return {
                onClick: (event) => {
                  navigate(`${row.id}`);
                },
              };
            }}
            style={{
              minWidth: "600px",
            }}
            size="middle"
          />
        )}

        {/* {isMobile && (
          <Table
            loading={isLoading}
            dataSource={mobileTableData}
            columns={mobileTableColumns}
            rowClassName={styles["table-row"]}
            onRow={() => {
              return {
                onClick: (event) => {
                  navigate("transactions/transaction-details");
                },
              };
            }}
            size="large"
          />
        )} */}
      </div>
    </div>
  );
};

const MainView = (props: ReachPathProps) => {
  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "../", name: "Unpaid" },
          { to: "", name: "Pending" },
          { to: "../history", name: "History" },
        ]}
      />
      <Pending />
    </div>
  );
};

export default function Transactions(props: ReachPathProps) {
  return (
    <DashboardShared title="Transactions">
      <Router>
        <Unpaid path="/" />
        <MainView path="pending" />
        <UnpaidDetails path="unpaid/:id" />
        <TransactionDetail path=":id" />
        <OrderDetails path="history/:id" />
        {/* <OrderIdl path=":id" /> */}
        <UnpaidDetails path="unpaid/:id" />
        <History path="history" />
      </Router>
    </DashboardShared>
  );
}
